import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import * as backend from '../build/auction/index.main.mjs'
import { getAccountInfo } from "../functions"
import md5 from 'blueimp-md5'
const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let algoexplorerapi_endpoint
if(providerEnv === "MainNet") {
  algoexplorerapi_endpoint = 'https://algoexplorerapi.io'
} else {
  algoexplorerapi_endpoint = 'https://testnet.algoexplorerapi.io'
}
const headingStyle =
{
  "height": "38px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "900",
  "fontSize": "32px",
  "lineHeight": "38px",
  "textAlign": "center",
  "letterSpacing": "0.1em",
  "textTransform": "uppercase",
  "color": "#2A3035",
  "opacity": "0.8",
}
const ContractLoader = ({
  // TODO: Use interface
  acc,
  id,
  stdlib,
  firstBlock,
  approvalProgram,
  showLoading = true,
  children
}) => {
  const [showSpinner, setShowSpinner] = useState(true)
  const [message, setMessage] = useState("Connecting to blockchain...")
  const [state, setState] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const onClose = () => {
    setState({
      ...state,
      showing: false
    })
  }
  useEffect(() => {
    if (message === "") return
    let timeout = 5000
    let interval = setInterval(() => {
      fetch(`${algoexplorerapi_endpoint}/v2/applications/${id}`)
        .then(response => response.json())
        .then(data => {
          if (data.message === "application does not exist") {
            /*
            enqueueSnackbar('Auction closed!', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            })
            */
            setMessage("")
            setState({ showing: false })
          }
        })
        .catch(e => console.log(e))
    }, timeout)
    return () => clearInterval(interval)
  })
  useEffect(() => {
    //fetch(`${algoexploererapi_endpoint}/v2/applications/${id}`)
    fetch(`${algoexplorerapi_endpoint}/idx2/v2/applications/${id}`)
      .then(response => response.json())
      .then(data => {
        console.log({data})
        if (data.message === "application does not exist") {
          setTimeout(() => {
            setMessage("Contract not found")
            setShowSpinner(false)
          }, 3000)
          setTimeout(() => {
            setMessage("")
          }, 5000)
          return
        }
        let createdRound = data.application['created-at-round']
        if(createdRound < firstBlock) {
          setTimeout(() => {
            setMessage("Contract not found")
            setShowSpinner(false)
          }, 3000)
          setTimeout(() => {
            setMessage("")
          }, 5000)
          return
        }
        let program = md5((data?.application?.params ?? {})['approval-program'] ?? '') 
        if(program !== approvalProgram) {
          setTimeout(() => {
            setMessage("Contract verification failed")
            setShowSpinner(false)
          }, 3000)
          setTimeout(() => {
            setMessage("")
          }, 5000)
          return     
        }

        console.log({program, approvalProgram, id})

        let ctc = acc.contract(backend, parseInt(id))
        let v = ctc.v.Auction
        let { a } = ctc
        Promise.all([
          ctc.getContractAddress(),
        ])
          .then(async ([
            addr,
          ]) => {
            let fAddr = stdlib.formatAddress(addr)
            let accInfo = await getAccountInfo(fAddr)
            let { assets } = accInfo
            let assetId
            if (assets.length > 0) {
              assetId = assets[0]['asset-id']
            }
            setState({
              showing: true,
              // from reach
              stdlib,
              // from ctc
              ctc,
              ctcAccInfo: accInfo,
              ctcAssetId: assetId,
              addr: fAddr,
              v,
              a,
              apis: a,
              // from app
              id,
              appId: id,
            })
          })
          .catch(e => {
            // BGYV: verifyContract failed: Cannot query for constructor transaction
            if(e.message.match(/verifyContract/)) {
              setTimeout(() => {
                setMessage("Contract verification failed")
                setShowSpinner(false)
              }, 3000)
              setTimeout(() => {
                setMessage("")
              }, 5000)
            }
            console.log(e.message)
          })
      })
      .catch((e) => console.log(e))
  }, [id])
  return (
    state
      ? state.showing && React.Children.map(children, (child, i) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child,
            { ...state, key: id, onClose, showLoading });
        }
        return child;
      }) : showLoading && message !== "" && <div style={{
        ...headingStyle,
        "fontWeight": "100",
        "color": "white",
        "marginTop": "100px"
      }}>
        {showSpinner && <Spinner
          as="span"
          animation="grow"
          size="lg"
          role="status"
          aria-hidden="true"
        />}
        {message}
      </div>
  )
}
export default ContractLoader