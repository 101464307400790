import { useEffect, useRef } from 'react'
import {
  useHistory,
  useParams
} from "react-router-dom"
import * as backend from '../build/offer/index.main.mjs'
import { useState } from "react";
import { Col, Image, Row } from "react-bootstrap"
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Icon from "react-crypto-icons";
import {
  getAsset,
  getAssetImage,
} from '../functions'
import './Sell.css'

const axios = require('axios')

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let algoexplorerapi_endpoint
if (providerEnv === "MainNet") {
  algoexplorerapi_endpoint = 'https://algoexplorerapi.io'
} else {
  algoexplorerapi_endpoint = 'https://testnet.algoexplorerapi.io'
}

const Buy = (props) => {
  var moment = require('moment-timezone');
  document.title = "Buy - NFT Jam"
  let timeout
  let history = useHistory()
  let { appId } = useParams()
  const sliderRef = useRef(null)
  let sliderTimeout
  const {
    acc,
    stdlib,
    ASSET_ID_JAM,
    ADDR_PLATFORM,
    ADDR_PLATFORM2,
    ADDR_DISCOVERY,
    ADDR_DISCOVERY2,
    ADDR_DISCOVERY3,
    ADDR_PRIVATE,
  } = props
  const [sliderProps, setSliderProps] = useState({})
  const [networkTime, setNetworkTime] = useState(0)
  const [state, setState] = useState({
    success: false,
    loading: false,
    bank: null,
    buy: null,
    seconds: 0,
    step: 0
  })
  const [assetTimeout, setAssetTimeout] = useState(null)
  const [asset, setAsset] = useState(null)
  const [query, setQuery] = useState({
    ASSETID: 0,
    STARTBID: 1,
    RESERVEPRICE: 100,
    DEADLINEDATE: moment().add(1, 'day'),
    ENABLEDISCOVERY: true,
    ROYALTYCENTS: 10
  })
  const getRoyaltyParams = rc => ({
    royaltyAddr: rc > 0
      ? String(query.CREATOR)
      : ADDR_PLATFORM,
    royaltyCents: rc === 0
      ? 1
      : rc
  })
  const handleSubmit = async () => {
    // validate sell nft form
    if (query.ASSETID === 0) {
      alert("Must enter valid asset id")
      return
    }
    else if (query.OFFERAMT <= 0) {
      alert("Offer amount must be greater than zero")
      return
    }
    else if (query.STARTBID <= 0) {
      alert("Start bid must be greater than zero")
      return
    }
    else if (query.RESERVEPRICE <= query.STARTBID) {
      alert("Reserve price must be greater tha start price")
      return
    }
    else if (moment().unix() > query.DEADLINEDATE.unix()) {
      alert("Deadline date must be in the future")
      return
    }
    else if (parseInt(query.ROYALTYCENTS) < 0) {
      alert(`Royalty % must be greater than or equal to 0`)
      return
    }
    else if (parseInt(query.ROYALTYCENTS) >= 99) {
      alert("Royalty % must be less than 99")
      return
    }

    let ctcAuctionInfo
    const getParams = () => ({
      token: parseInt(query.ASSETID),
      offer: stdlib.parseCurrency(query.OFFERAMT),
      addr: ADDR_DISCOVERY3,
      addr2: ADDR_PLATFORM2,
      creator: asset?.creator || ADDR_PLATFORM2,
    })
    const signal = async () => {
      let image = await getAssetImage(parseInt(query.ASSETID))
      console.log({ image })
      setState({
        ...state,
        //loading: true,
        success: true,
        appId: ctcAuctionInfo,
        image
      })
    }
    const closed = () => {
      console.log("CLOSED")
    }
    const commonInteract = {
      ...stdlib.hasConsoleLogger
    }
    const auctioneerInteract = {
      ...commonInteract,
      getParams,
      signal,
      closed
    }
    // DEBUG
    //console.log(stdlib.bigNumberToNumber(await stdlib.getNetworkSecs()))
    //console.log(getParams())
    if (!appId) {

      setState({ ...state, loading: true })
      console.log("Launching new auction ...")
      // TODO depreciated
      //const ctcAuction = acc.deploy(backend)
      const ctcAuction = acc.contract(backend)
      Promise.all([
        //backend.Constructor(ctcAuction, constructorInteract),
        backend.Auctioneer(ctcAuction, {
          ...stdlib.hasConsoleLogger,
          getParams: () => ({
            token: parseInt(query.ASSETID),
            addr: ADDR_DISCOVERY3,
            addr2: ADDR_PLATFORM2,
            creator: asset?.creator || ADDR_PLATFORM2,
            offer: stdlib.parseCurrency(query.OFFERAMT)
          }),
          signal: async () => {
            let image = await getAssetImage(parseInt(query.ASSETID))
            console.log({ image })
            setState({
              ...state,
              //loading: true,
              success: true,
              appId: ctcAuctionInfo,
              image
            })
          },
          closed: () => {
            console.log("CLOSED")
          }
        })
      ])
      ctcAuctionInfo = await ctcAuction.getInfo()
      appId = ctcAuctionInfo
      //history.push(`/sell/${appId}`)
      //setState({ ...state, loading: false })
    } else {

      if (!query.ASSETID
        || !query.STARTBID
        || !query.RESERVEPRICE
        || !query.DEADLINEDATE) {
        alert("Enter Asset id, Start bid, and Deadline date to submit")
        return
      }
      setState({ ...state, loading: true })
      ctcAuctionInfo = parseInt(appId)
      console.log("Launching auction ...")
      // TODO depreciated
      //let ctcAuction = acc.attach(backend, ctcAuctionInfo)
      let ctcAuction = acc.contract(backend, ctcAuctionInfo)
      Promise.all([
        backend.Auctioneer(ctcAuction, auctioneerInteract)
      ])
      appId = ctcAuctionInfo

    }
  }
  const initialState = {
    step: appId ? 1 : 0,
    loading: false
  }

  const SubmitButton = (props) => {
    const {
      loading,
      label,
      onClick
    } = props
    return loading
      ? (
        <div style={{
          ...buttonStyle,
          ...buttonTypographyStyle,
          ...{
            "marginTop": "40px",
            "opacity": ".9"
          }
        }}
          disabled><Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            style={{
              "height": "12px",
              "width": "12px"
            }}
          />Loading...</div>
      )
      : (
        <div role="button" style={{ ...buttonStyle, ...buttonTypographyStyle, ...{ "marginTop": "40px" } }} onClick={() => { onClick() }}>
          {label}
        </div>
      )
  }
  const handleChange = ({ target }) => {
    //let timeout
    let { name, value } = target
    console.log({ name, value })
    switch (name) {
      case 'ASSETID':
      if(parseInt(value) > 0) {
        if(assetTimeout) {
          clearTimeout(assetTimeout)
        }
        //let timeout = setTimeout(() => {
          getAsset(value)
            .then(({data})=> {
              if(data?.url) {
                getAssetImage(data.id)
                  .then(image => setAsset({...data, image}))
                  .catch(() => setAsset(null))
              }
            })
            .catch(e => setAsset(null))
        //}, 1000)
        //setAssetTimeout(timeout)
      }

        /*
        let CREATOR = acc.assets.filter(el => el['asset-id'] === value)[0].creator
        if (CREATOR === acc.address) {
          setQuery({
            ...query,
            [name]: value,
            CREATOR,
            ROYALTYCENTS: 0,
          })
        } else {
          setQuery({
            ...query,
            [name]: value,
            CREATOR
          })
        }
        break
        */
      case 'STARTBID':
      case 'OFFERAMT':
      case 'RESERVEPRICE':
      case 'ROYALTYCENTS':
        if (value.length > 1 && value[0] === '0') {
          value = value.slice(1)
        }
        setQuery({
          ...query,
          [name]: value
        })
        break
      default:
    }
  }
  const handleSellOneMore = () => {
    appId = null
    history.push(`/sell`)
    setState({
      success: false
    })
    setQuery({})
  }
  const serviceNameTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "900",
    "fontSize": "32px",
    "lineHeight": "38px",
    "textAlign": "center",
    "letterSpacing": "0.1em",
    "textTransform": "uppercase"
  }
  const cardStyle =
  {
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "padding": "50px 56px",
    "width": "565px",
    //"height": "645px",
    "paddingBottom": "20px"
  }
  const compactCardStyle =
  {
    "position": "fixed",
    "top": "15%",
    "left": "5vw",
    "width": "90vw",
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "padding": "20px 30px",
    "marginTop": "0vh",
    //"maxWidth": "90vw",
    //"maxHeight": "70vh",
    //"width": "465px",
    //"height": "588px"
  }

  const Success = ({ assetId }) => {
    const [image, setImage] = useState(null)
    useEffect(() => {
      if (!image) {
        (async () =>
          getAssetImage(assetId)
            .then(data => setImage(data)))()
      }
    }, [image, assetId])
    const cardStyle =
    {
      "marginTop": "5vh",
      "width": "391px",
      "background": "#FFFFFF",
      "borderRadius": "36px",
      "padding": "44px"
    }
    const compactCardStyle =
    {
      "marginTop": "5vh",
      "width": "90vw",
      "background": "#FFFFFF",
      "borderRadius": "36px",
      "padding": "44px"
    }
    const imageStyle = {
      "marginTop": "26px"
    }
    const labelStyle = {
      "fontFamily": "Rubik",
      "fontStyle": "normal",
      "fontWeight": "300",
      "fontSize": "12px",
      "lineHeight": "14px",
      "color": "#C558F3"
    }
    const valueStyle =
    {
      "fontFamily": "Rubik",
      "fontStyle": "normal",
      "fontWeight": "500",
      "fontSize": "14px",
      "lineHeight": "17px",
      "color": "#55595D",
      "marginTop": "5px"
    }
    const buttonContainerStyle =
    {
      "display": "flex",
      "justifyContent": "center"
    }
    const buttonStyle =
    {
      "width": "100%",
      "maxWidth": "304px",
      "height": "50px",
      "lineHeight": "50px",
      "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
      "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
      "borderRadius": "44px",
      "marginTop": "55px",
      "color": "#ffffff"
    }
    const sellMoreStyle =
    {
      "height": "17px",
      "fontFamily": "Rubik",
      "fontStyle": "normal",
      "fontWeight": "normal",
      "fontSize": "14px",
      "lineHeight": "17px",
      "textAlign": "center",
      "textTransform": "uppercase",
      "color": "#C054F2",
      "marginTop": "32px"
    }
    const Template = () => {
      const displayAlgo = (val) => <div style={{
        "display": "inline-flex",
        "columnGap": "5px"
      }}>
        <Icon name="algo" size={14} />
        <span>{val}</span>
      </div>
      return <>
        <div style={serviceNameTypographyStyle}>Success</div>
        <div>
          <Image style={imageStyle} src={image} alt="NFT" fluid />
          <Row style={{ marginTop: '34px' }}>
            {[
              {
                label: "Asset id",
                value: query.ASSETID,
                displayValue: (val) => `${val}`
              },
              {
                label: "Start bid",
                value: query.STARTBID,
                displayValue: displayAlgo
              },
              {
                label: "Reserve price",
                value: query.RESERVEPRICE,
                displayValue: displayAlgo
              },
              {
                label: "Deadline date",
                value: query.DEADLINEDATE,
                displayValue: (val) => `${val}`
              }
            ].map(el => <Col style={{ padding: '0px', height: '38px', overflow: 'hidden' }}>
              <Row>
                <Col xs={12} className="text-left" style={labelStyle}>{el.label}</Col>
                <Col xs={12} style={valueStyle}>{el.value ? el.displayValue(el.value) : '-'}</Col>
              </Row>
            </Col>)}
          </Row>
          <div style={buttonContainerStyle}>
            <div role="button" style={buttonStyle} onClick={() => history.push(`/offer/${state.appId}`)}>OK</div>
          </div>
          <div role="button" style={sellMoreStyle} onClick={handleSellOneMore}>Sell One More NFT</div>
        </div>
      </>
    }
    return <>
      <div className="d-none d-sm-inline-block" style={cardStyle}>
        <Template />
      </div>
      <div className="d-xs-inline-block d-sm-none" style={compactCardStyle}>
        <Template />
      </div>
    </>
  }
  const labelTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "300",
    "fontSize": "12px",
    "lineHeight": "14px",
    "color": "#C558F3"
  }
  const labelStyle =
  {
    "height": "14px",
    "marginBottom": "4px"
  }
  const buttonStyle =
  {
    "height": "50px",
    "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
    "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
    "borderRadius": "44px",
    "marginTop": "46px"
  }
  const buttonTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "14px",
    "lineHeight": "50px",
    "textAlign": "center",
    "textTransform": "uppercase",
    "color": "#FFFFFF"
  }
  const cancelStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "14px",
    "lineHeight": "17px",
    "textAlign": "center",
    "textTransform": "uppercase",
    "color": "#FF4747",
    "marginTop": "32px"
  }
  return <div id="sell" style={{ paddingBottom: "100px" }}>
    {!state.success ? <>
      <div className="d-xs-block d-sm-none" style={{ ...compactCardStyle }} >
        <div style={{ ...serviceNameTypographyStyle, ...{ "fontSize": "22px" } }}>Buy A NFT</div>
        <Stack className="mt-3" spacing={4}>
          {[
            {
              name: "ASSETID",
              label: "Asset id"
            }
          ].map(el =>
            <Box style={{ "textAlign": "left" }}>
              {!appId
                ? <label style={{ ...labelStyle, ...labelTypographyStyle, "color": "grey" }}>{el.label}</label>
                : <label style={{ ...labelStyle, ...labelTypographyStyle }}>{el.label}</label>}
              <TextField
                name={el.name}
                id="standard-number"
                fullWidth={true}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                value={query[el.name]}
                onChange={handleChange}
              />
            </Box>)}
            {asset && <Box>
              {asset?.assetname} ({asset?.unitname})
              <Image src={asset?.url} fluid />
            </Box>}
          <SubmitButton onClick={handleSubmit} label={appId ? "Step 2: Configure Auction" : "Step 1: Create Auction"} loading={state.loading} />
          <div role="button" style={cancelStyle} onClick={() => history.push("/")}>Cancel</div>
        </Stack>
      </div>
      <div id="sell" className="d-none d-sm-block" style={cardStyle}>
        <div style={serviceNameTypographyStyle}>Buy A NFT</div>
        <Stack className="mt-4 pt-1" spacing={5}>
          {/*!!appId
            ? <Box>
              <NFTRocket />
            </Box>
            : */<>
              {[
                {
                  name: "ASSETID",
                  label: "Asset id",
                },
              ].map(el =>
                <Box style={{ "textAlign": "left" }}>
                  <label style={{ ...labelStyle, ...labelTypographyStyle}}>{el.label}</label>
                  <TextField
                    name={el.name}
                    id="standard-number"
                    fullWidth={true}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    value={query[el.name]}
                    onChange={handleChange}
                  />
                </Box>)}
                {asset && <Box>
                  {asset?.assetname} ({asset?.unitname})
                  <Image src={asset?.url} fluid />
                </Box>}
                {asset && <Box style={{ "textAlign": "left" }}>
                  <label style={{ ...labelStyle, ...labelTypographyStyle }}>Offer amount</label>
                  <TextField
                    name="OFFERAMT"
                    id="standard-number"
                    fullWidth={true}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    value={query.OFFERAMT}
                    onChange={handleChange}
                  />
                </Box>}
            </>}
          <SubmitButton onClick={handleSubmit} label={appId ? "Step 2: Configure Auction" : "Step 1: Create Auction"} loading={state.loading} />
          <div role="button" style={cancelStyle} onClick={() => history.push("/")}>Cancel</div>
        </Stack>
        <div className="jam default" style={{ margin: '24px auto' }}>
          {false && <div style={{ 'textAlign': 'left' }}>
            <label style={{ ...labelStyle, ...labelTypographyStyle }}>{"Asset id"}</label>
            {acc?.assets && <Form.Select name="ASSETID" size="lg" onChange={handleChange}>
              <option></option>
              {acc.assets.filter(el => el.amount > 0).map(el =>
                <option value={el['asset-id']}>
                  {((assets) => assets.length > 0 ? ((asset) => `${asset.index} : ${asset?.params?.name} (${asset?.params["unit-name"]}) : ${el.amount}`)(assets[0]) : "")(((assetId) => acc.assetInfo.filter(el => el.index === assetId))(el["asset-id"]))}
                </option>)}
            </Form.Select>}
          </div>}
        </div>
      </div>
    </> : <Success assetId={query.ASSETID} />
    }
  </div >
}

export default Buy